import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Head from '../../components/head'
import SocialCard from '../../components/social-card'
import ScrollDetector from '../../components/pauseScrollDetector'

import CardImage from './assets/card.png'

import * as styles from './styles/pause.module.scss'

const Privacy = () => (
	<div className={styles.content}>
		
		<Helmet>
			<html data-style="pause" />
			
			<link rel="icon" type="image/png" sizes="16x16" href="/pause/favicons/16x16.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/pause/favicons/32x32.png" />
			<link rel="icon" type="image/png" sizes="48x48" href="/pause/favicons/48x48.png" />
			<link rel="icon" type="image/png" sizes="64x64" href="/pause/favicons/64x64.png" />
			<link rel="icon" type="image/png" sizes="192x192" href="/pause/favicons/192x192.png" />
			<link rel="apple-touch-icon" sizes="120x120" href="/pause/favicons/touch-120x120.png" />
			<link rel="apple-touch-icon-precomposed" sizes="152x152" href="/pause/favicons/touch-152x152.png" />
			<link rel="apple-touch-icon-precomposed" sizes="167x167" href="/pause/favicons/touch-167x167.png" />
			<link rel="apple-touch-icon-precomposed" sizes="180x180" href="/pause/favicons/touch-180x180.png" />
			
			<meta name="theme-color" content="hsl(208, 20%, 99%)" media="(prefers-color-scheme: light)" />
			<meta name="theme-color" content="hsl(208, 90%, 19%)" media="(prefers-color-scheme: dark)" />
		</Helmet>
		
		<SocialCard 
			title="Pause Privacy Policy"
			image={CardImage}
			isLargeImage={true}
		/>
		
		<Head title="Pause" />
		
		<ScrollDetector />
		
		<div className={styles.headerWrapper}>
			
			<section className={styles.header}>
				<div>
					<h2>Pause Privacy Policy</h2>
					<p>Pause does not collect or transmit any personal data.</p>
					<h3 className={styles.privacyContactHeader}>Contact</h3>
					<p>If you have any questions, please feel free to <a href="mailto:pause@harshil.net">contact me</a>.</p>
				</div>
				
			</section>
			
		</div>
		
		<section className={styles.info}>
			<p>Return to the <Link to="/pause">main site</Link></p>
		</section>
	</div>
)

export default Privacy
